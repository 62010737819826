<p-toast></p-toast>
<div class="eup-ov-grid-wrapper">
    <div class="container">
    <!--          Cards wrapper  -->
      <div class="row">
        <div class="col-md-12">
          <div class="eup-ov-cards-heading ">
            <h5>Quiz Details</h5>
          </div>
          <div class="eup-ov-cards-wrapper col-sm-12">
            <!-- card 1 -->
            <div class="eup-ov-single-card col-sm-6 col-md-4">
              <div class="eup-blue-card">
                <p-card styleClass="eup-blue-card">
                 
                  <div class="eup-ov-card-value text-center"> <p-skeleton width="4rem" styleClass="p-mb-2 p-mx-auto" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">{{totalQuizzes}}</span></div>
                  <div class="eup-ov-card-text"><p-skeleton width="10rem" styleClass="p-mb-2" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">Quiz Generated</span> </div>
                </p-card>
              </div>
            </div>
            <!-- card 2 -->
            <div class="eup-ov-single-card col-sm-6 col-md-4 ">
              <div class="eup-red-card">
                <p-card styleClass="eup-red-card">
                  <div class="eup-ov-card-value"><p-skeleton width="4rem" styleClass="p-mb-2 p-mx-auto" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">{{QuizSuspended}}</span> </div>
                  <div class="eup-ov-card-text"><p-skeleton width="10rem" styleClass="p-mb-2" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">Quiz Suspended</span>  </div>
                </p-card>
              </div>
            </div>
            <!-- card 3 -->
            <div class="eup-ov-single-card col-sm-6 col-md-4 ">
              <div class="eup-green-card">
                <p-card styleClass="eup-green-card">
                  <div class="eup-ov-card-value"><p-skeleton width="4rem" styleClass="p-mb-2 p-mx-auto" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">{{QuizCompleted}}</span> </div>
                  <div class="eup-ov-card-text"><p-skeleton width="10rem" styleClass="p-mb-2" *ngIf="contentLoader"></p-skeleton> <span *ngIf="!contentLoader">Quiz Completed</span>  </div>
                </p-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <!-- TABLE -->

      <div class="useractivityParent">
        <div class="useractivity_left_parent">
        <div class="useractivity_table">
            <!-- [style]="{width:'78vw'}" -->
    <p-table id="userActivityTable" #dt [value]="userData" [scrollable]="true"   (onLazyLoad)="loadCustomers($event)" [paginator]="true" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [loading]="loading"  [rows]="selectedNum" [totalRecords]="userData.length" [globalFilterFields]="['display_name', 'ID', 'user_email']" dataKey="name">
    
        <ng-template pTemplate="caption" class="table_top_bar">
            <div class="p-d-flex p-ai-center p-jc-between">
                <h5 class="p-m-0"><div class="Dropdown_columnNumber">
                    <span class="dropdown_text">Show </span> 
                    <p-dropdown [options]="NumofRows" [(ngModel)]="NumofRows.numOfRows" optionLabel="numOfRows" (onChange)="selectNum(NumofRows.numOfRows)"></p-dropdown> 
                    <span class="dropdown_text"> entries</span>
                    </div></h5>
                <span class="p-input-icon-left mr-3">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                </span>
            </div>
        </ng-template>
    
        <ng-template pTemplate="header" class="table_header" styleClass="myTable" class="some-custom-class-name" pResizableColumn>
            <tr>
                <th pSortableColumn="id" class="id_prevTest_table" [ngStyle]="{'width':'55px'}">#</th>
                <th pSortableColumn="ID" class="name" [ngStyle]="{'width':'100px'}">USER ID <p-sortIcon field="ID"></p-sortIcon></th>
                <th pSortableColumn="display_name" [ngStyle]="{'width':'180px'}">USER <p-sortIcon field="display_name"></p-sortIcon></th>
                <th pSortableColumn="user_email" [ngStyle]="{'width':'160px'}">EMAIL <p-sortIcon field="user_email"></p-sortIcon></th>
                <th pSortableColumn="last_login" [ngStyle]="{'width':'160px'}">LAST LOGIN <p-sortIcon field="last_login"></p-sortIcon></th>
                <th  [ngStyle]="{'width':'120px'}">REPORTING </th>
                <th [ngStyle]="{'width':'120px'}">LOGOUT</th>
                <th [ngStyle]="{'width':'130px'}">Change Pass</th> 
                <th [ngStyle]="{'width':'120px'}">Renew Btn</th>               
            </tr>
            
        </ng-template>
        <ng-template pTemplate="body" let-user let-i="rowIndex" class="table_body">
            <tr>
                <td class="id_prevTest_table" [ngStyle]="{'width':'55px'}">{{i+1}}</td>
                <td class="name" [ngStyle]="{'width':'100px'}">{{user.ID}}</td>
                <td class="score" [ngStyle]="{'width':'180px'}">{{user.display_name}}</td>
                <td class="date" [ngStyle]="{'width':'160px'}">{{user.user_email}}</td>
                <td class="last_login" [ngStyle]="{'width':'160px'}" pTooltip="{{user.loginTime}}">{{user.loginTime}}</td>
                <td class="mode" [ngStyle]="{'width':'120px'}" > <i routerLink="/admin/performance" (click)="getUserDetails(user.ID)" class="pi pi-file-o"></i></td>  
                <td class="logout" [ngStyle]="{'width':'120px'}" ><i (click)="logoutAnyUser(user.ID)" class="pi pi-sign-out"></i></td>
                <td class="logout" [ngStyle]="{'width':'130px'}" ><i (click)="forceLogout(user.ID)" class="pi pi-arrow-circle-left"></i></td>
                <td class="renew_btn logout" [ngStyle]="{'width': '120px'}"><p-inputSwitch (onChange)="setRenewToggle(user)" [(ngModel)]="user.RenewToggle"></p-inputSwitch>
                </td>
            </tr>
        </ng-template>
    </p-table>
    </div>
    </div>
    
    </div>

</div>