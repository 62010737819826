import { Component, OnInit } from '@angular/core';
import { AutoLogout } from 'src/app/models/globals';
import { AdminService } from 'src/app/shared/services/admin.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss'],
  providers: [MessageService]
})
export class HomeScreenComponent implements OnInit {
   // @ts-ignore
   subscriptions: Subscription = [];
  contentLoader = true;
  totalQuizzes: any;
  QuizCompleted: any;
  QuizSuspended: any;
  userData: any;
  NumofRows: any;
  loading!: boolean;
  selectedNum: any;

  checked2
  constructor(private messageService: MessageService,private adminService: AdminService) { }

  ngOnInit(): void {
    
    document.getElementById('eup-navbar-heading').innerText = 'Dashboard';
    this.selectedNum =5
    this.loading = false;
    this.NumofRows = [
      {numOfRows: 5},
      {numOfRows: 10},
      {numOfRows: 15},
      {numOfRows: 20},
      {numOfRows: 25}
  ];
    this.userData = [{name:1},{name:1},{name:1},{name:1},{name:1},{name:1},{name:1},{name:1},{name:1},{name:1}]
    this.contentLoader = false;

    this.getQuizDetails()
    this.getUsers();
  }

  // LAZY DEFAULT FUNCTIONS
  loadCustomers(event: LazyLoadEvent) {
    this.loading = false;
}

selectNum(a: any): void{
  // this.selectedNum = this.duplicateSelectedNum;
  this.selectedNum = a.numOfRows;
}
  getQuizDetails(){
    this.subscriptions.push(
      this.adminService.getQuizDetails().subscribe((res: any) => {
          this.totalQuizzes = res.totalQuizzes;
          this.QuizCompleted = res.completedQuizzes;
          this.QuizSuspended = res.suspendedQuizzes
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
  }

  getUsers(){
    this.subscriptions.push(
      this.adminService.getUsers().subscribe((res: any) => {
          this.userData = res.users;
          this.userData.forEach(user => {
            if(user.RenewToggle == 1 || user.RenewToggle == null){
              user.RenewToggle = true;
            } else{
              user.RenewToggle = false;
            }
          });
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
  }

  getUserDetails(userId: any){
    localStorage.setItem("userIDForReport", JSON.stringify(userId))
  }

  logoutAnyUser(userId: any){
    this.subscriptions.push(
      this.adminService.logoutUser(userId).subscribe((res: any) => {
          if(res.status){
            this.messageService.add({severity:'error', summary: 'Message', detail: 'User Is Logged Out'});
          }
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
  }


  forceLogout(userId: any){
    this.subscriptions.push(
      this.adminService.setForceLogout(userId).subscribe((res: any) => {
        console.log("******** ", res); 
          if(res.status){
            this.messageService.add({severity:'error', summary: 'Message', detail: 'User Is Logged Out & asked to change his password'});
          }
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );

  }

  setRenewToggle(user: any){
    console.log(user)
    var data = {
      userId: user.ID,
      renewed: (user.RenewToggle)?1:0 
    }
    this.subscriptions.push(
      this.adminService.setRenewToggle(data).subscribe((res: any) => {
          if(res.status){
            this.messageService.add({severity:'success', summary: 'Message', detail: `Toggle button is ${(user.RenewToggle? 'ON': 'OFF')}`});
          }
        },
        (        err: any) => {
          AutoLogout(err);
        }
      )
    );
  }


}
